<template>
  <div class="app-header-top">
      <div class="aht_son">
          <router-link :to="{path:'/fhome'}"> <span>首页</span></router-link>

          <span style="line-height: 43px">|</span>
          <router-link :to="{path:'/configurationList'}"><span>我的项目</span></router-link>
          <span style="line-height: 43px">|</span>
          <span @click="goSh" v-if="show=='true'">审核管理</span>
          <span style="line-height: 43px" v-if="show=='true'">|</span>
          <router-link :to="{path:'/buyCarList'}"><span>购物车<span style="color: red;padding-left: 0px;">({{carNum}})</span></span></router-link>
          <!--<span style="line-height: 43px">|</span>
          <span>我的报价</span>-->
          <span style="line-height: 43px">|</span>
         <span>联系我们</span>
          <span style="line-height: 43px">|</span>
          <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                 <span>{{name}}</span><i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item><span @click="goPer">修改密码</span></el-dropdown-item>
                  <el-dropdown-item><span @click="out">退出登录</span></el-dropdown-item>
              </el-dropdown-menu>
          </el-dropdown>



          <!--<span @click="out">退出登录</span>-->
      </div>

  </div>
</template>

<script>
import { removeToken } from "@/Util/auth";
import {
    shopGetCount,
} from "@/Api/finance/home";
import { encrypt,decrypt } from "@/Util/auth";
export default {
  name: "app-header-top",
  components: {

  },
  data() {
    return {
        name:localStorage.getItem('username'),
        show:localStorage.getItem('shShow'),
        carNum: '0'
     };
  },
  computed: {
      isFollow () {
          return this.$store.state.carNum;　　//需要监听的数据
      }
  },
    watch:{
        isFollow (newVal, oldVal) {

            this.carNum=this.$store.state.carNum
        }
    },
  methods: {
      goSh(){
          let URL
          if(window.location.hostname == 'cpq.shipgroup.net'){
              URL = 'http://cpq.shipgroup.net:8083/#/fhome/audit'
          } else {
              URL = 'http://192.168.12.105:8083/#/fhome/audit'
          }
          window.open(URL)
      },
      goPer(){
          this.$router.push({
              name: "personal"
          });
      },
      out() {
      //登出
          removeToken();
          localStorage.removeItem('username'),
          localStorage.removeItem('shShow'),
          this.$router.push({
              name: "login"
          });

    },
      getCount(){

          shopGetCount().then(res => {
              let dd= JSON.parse(decrypt(res))
              console.log(dd)
              if (dd.code == 200) {

                  this.carNum=dd.data
              }else {
                  alert(dd.msg);
              }

          })
      }
  },
    mounted(){

        this.getCount()
        /*this.name=*/
        // if(sessionStorage.getItem('carNum') !=''){
        //     this.carNum=sessionStorage.getItem('carNum')
        // }
        // window.addEventListener('setItem', ()=> {
        //     this.carNum = sessionStorage.getItem('carNum');
        // })
    }
};
</script>

<style lang="less" scoped>
.app-header-top{
    width: 100%;
    height: 42px;
    background: #2C2D2D;
    .aht_son{
        width: 1200px;
        margin: 0px auto;
        text-align: right;
        span{
            color: #CCCCCC;
            font-size: 14px;
            line-height: 42px;
            padding-left: 10px;
            cursor: pointer;
        }
    }

}


</style>
